<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <a
        style="color: blue;"
        @click="loadInventory"
        v-on="on"
        v-bind="attrs"
      >
        {{ unitCount }}
      </a>
    </template>
    <v-list>
      <v-sheet max-height="600px" min-width="500px">
    <v-simple-table class="table pa-5">
      <thead>
        <tr>
          <th><v-checkbox v-if="mode !== 'view'" hide-details dense @click="toggleAll" v-model="selectedAll" :value="true"></v-checkbox></th>
          <th>{{$vuetify.lang.t('$vuetify.inventory.status')}}</th>
          <th>{{$vuetify.lang.t('$vuetify.inventory.code')}}</th>
          <th>{{$vuetify.lang.t('$vuetify.inventory.weight')}}</th>
          <th>{{$vuetify.lang.t('$vuetify.inventory.unit')}}</th>
        </tr>
      </thead>
      <tr v-for="(unit, index) in units" :key="unit.id">
        <td>
          <span v-if="mode == 'view'">{{ index + 1 }}.</span>
          <v-checkbox :disabled="unit.status !== 'Available'" @click="updateSelected" v-model="unit.selected" class="ml-1" hide-details dense v-else :value="true"></v-checkbox>
        </td>
        <td>
          <v-tooltip v-if="unit.description" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" text v-bind="attrs" v-on="on">
                {{ translateStatus(unit.status) }}
              </v-btn>
            </template>
            <span>{{unit.description}}</span>
          </v-tooltip>
          <span v-else>{{ translateStatus(unit.status) }}</span>
        </td>
        <td>
          {{ unit.code }}
        </td>
        <td>{{ unit.amountPerUnit | formatNumber }}{{ unit.unitMeasure }}</td>
        <td>
          {{ unit.unitShare }}
        </td>
      </tr>
    </v-simple-table>
      </v-sheet>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      type: [String, Number, Object],
      required: false,
      default: null,
    },
    count: {
      type: Number,
      required: false,
      default: 0,
    },
    mode: {
      type: String,
      required: false,
      default: "view"
    }
  },
  data: () => {
    return {
      inventoryId: null,
      units: null,
      unitCount: null,
      selectedAll: false
    };
  },
  mounted() {
    if(typeof this.value === 'object'){
      const inventory  = this.value;
      this.inventoryId = inventory.id;
      this.units = inventory.unitDetails;
      this.unitCount = this.units ? this.units.length:0;
    }
    else{ 
      this.inventoryId = this.value;
      this.unitCount = this.count;
    }
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    loadInventory() {
      if(!this.units){
        console.log("Loading inventory units...");
        this.$axios("inventory/unit-details/by-inventory/" + this.inventoryId).then(
          (response) => {
            this.units = response.data;
            if (this.units && this.units.length > 0) {
              this.unitCount = this.units.length;
            }
            console.log(this.units);
          }
        );
      }
    },
    updateSelected(){
      this.$emit('selectionChanged', {inventoryId: this.inventoryId, units: this.units})
    },
    toggleAll(){
      console.log("Toggling all checked")
      this.units.forEach(u => {
        if(u.status == "Available"){
          u.selected = this.selectedAll
        }
      });

      this.updateSelected();
    },
    translateStatus(status) {
      if (status == "Available") {
        return this.$vuetify.lang.t("$vuetify.inventory.available");
      } else if (status == "Not Available") {
        return this.$vuetify.lang.t("$vuetify.inventory.notAvailable");
      }
    },
  },
};
</script>

<style></style>
